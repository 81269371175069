.vipContainer {
	.part1 {
		background: radial-gradient(
			195.68% 100% at 50% 0%,
			rgba(254, 44, 85, 0.5) 0%,
			rgba(20, 20, 20, 0.2) 24.65%,
			#000 100%
		);
		background-repeat: no-repeat;
	}
	.part2 {
		.vipModule {
			margin: 0 auto;
			margin-top: 60px;
			width: 664px;

			.tr {
				display: flex;
				font-weight: 400;
				justify-content: center;
				border-bottom: 1px solid rgba(255, 255, 255, 0.06);
				.icon {
					width: 24px;
					height: 24px;
				}
			}
			.header {
				font-size: 16px;
				color: #fff;
				font-weight: 900;
				letter-spacing: 0.48px;
			}
			div[class*='td'] {
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				padding: 16px;
			}
			.td1 {
				text-align: start;
				width: 360px;
				color: #fff;
				font-size: 16px;
				line-height: 24px; /* 150% */
				letter-spacing: 0.48px;
				text-transform: capitalize;
			}
			.td2 {
				width: 128px;
			}
			.td3 {
				width: 176px;
				background-color: rgba(255, 255, 255, 0.06);
			}
		}
	}
	.part4 {
		.vipList {
			display: flex;
			justify-content: space-between;
			.priceItem {
				width: 390px;
				box-sizing: border-box;
				border-radius: 16px;
				background: rgba(255, 255, 255, 0.06);
				padding: 24px;

				&.price0 {
					color: #c7faf8;
					.btn {
						background: #c7faf8;
						color: #116d6a;
					}
				}
				&.price1 {
					color: #ffe58f;
					.btn {
						background: #ffe58f;
						color: #734a03;
					}
				}
				&.price2 {
					color: #ffc7cd;
					.btn {
						background: #ffc7cd;
						color: #721426;
					}
				}
				.dateUnit {
					font-size: 20px;
					font-style: normal;
					font-weight: 900;
					line-height: 30px; /* 150% */
					height: 30px;
				}
				.template {
					margin-top: 24px;
					display: flex;
					align-items: self-start;
					font-weight: 900;
					.unit,
					.cny {
						font-size: 12px;
						line-height: 20px;
					}
					.money {
						margin: 0 2px;
						font-size: 40px;
						line-height: 42px;
						letter-spacing: 1.2px;
					}
				}
				.tips {
					font-size: 14px;
					text-align: left;
					color: #fff;
				}
				.btn {
					height: 48px;
					padding: 12px 32px;
					border-radius: 48px;
					font-size: 16px;
					font-weight: 900;
				}
			}
		}
	}
}
