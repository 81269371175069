.sellerStationLink {
	overflow: hidden;
	color: var(--brand-cyan-normal-focus, #199a97);
	text-overflow: ellipsis;
	font-family: Avenir;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 153.846% */
	text-decoration-line: underline;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

.sellerStationLink:hover {
	color: var(--brand-cyan-normal-focus, #199a97);
}

.sellerStationLink:active {
	color: var(--brand-cyan-normal-focus, #199a97);
}
