.ant-btn-lg {
	height: auto;
}

.ant-btn-primary {
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

/* 红色 checkbox */
.red-check:hover .ant-checkbox .ant-checkbox-inner {
	border-color: #fe2c55 !important;
}

.red-check .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #fe2c55 !important;
	border-color: #fe2c55;
}
.ant-radio-wrapper:hover .ant-radio-inner {
	border-color: #08979c !important;
}
.ant-radio-checked .ant-radio-inner {
	background: #fff !important;
	border-color: #08979c !important;
}
.ant-radio-checked .ant-radio-inner:after {
	background: #08979c;
	width: 20px;
	height: 20px;
	margin-block-start: -10px;
	margin-inline-start: -10px;
}
.common-drawer {
	.ant-drawer-header {
		padding: 16px 12px 16px 20px !important;
		// .ant-drawer-title {
		// 	font-size: 20px !important;
		// }
	}
	.ant-drawer-body {
		padding: 16px 20px !important;
	}
}
/* 公共table */
.common-table {
	.ant-table-thead .ant-table-cell {
		background-color: #fff;
		color: rgba(0, 0, 0, 0.65);
		font-size: 14px;
		font-style: normal;
		font-weight: 900;
		line-height: 22px;
		padding-bottom: 8px;
		padding-left: 12px !important;
		padding-right: 12px !important;
		&::before {
			width: 0px !important;
		}
	}
	.ant-table-expanded-row {
		.ant-table {
			margin: -16px 0 !important;
		}
	}
	.ant-table-expanded-row-fixed {
		width: 100% !important;
		position: relative !important;
	}
}
.ant-table-row.ant-table-row-selected > .ant-table-cell {
	background: #f5f5f5 !important;
}
.ant-select-item-option-selected {
	background: #f5f5f5 !important;
}

.show-two-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.click-event-style {
	overflow: hidden;
	color: #199a97;
	text-overflow: ellipsis;
	font-family: Avenir;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	text-decoration-line: underline;
	cursor: pointer;
}
.ant-typography {
	margin-bottom: 0;
}
.ant-typography-copy {
	svg {
		fill: #199a97;
	}
}
/* 弹窗按钮 */
.ant-modal-footer button {
	border-radius: 40px;
	min-width: 76px;
	height: 40px;
}

.ant-btn-primary.cyan-plain {
	border-radius: 40px;
	background: #199a97;
	min-width: 80px;
	&:hover {
		background: #1ebcb8 !important;
	}
	&:active {
		background: #116d6a !important;
	}
}
.ant-btn-link.cyan-plain {
	color: #199a97;
	&:hover {
		color: #1ebcb8 !important;
	}
	&:active {
		color: #116d6a !important;
	}
}
.ant-btn-default.cyan-plain {
	border-radius: 40px;
	color: #199a97;
	min-width: 80px;
	border-color: #199a97;

	&:hover {
		border-color: #1ebcb8 !important;
		color: #1ebcb8 !important;
	}
	&:active {
		border-color: #116d6a !important;
		color: #116d6a !important;
	}
}
//  工作台弹窗footer按钮
.workbench-modal {
	.ant-modal-title {
		font-size: 20px;
		margin-bottom: 16px;
	}

	.ant-modal-footer {
		.ant-btn-primary {
			border-radius: 40px;
			background: #199a97;
			min-width: 80px;
			font-weight: 800;
			&:hover {
				background: #1ebcb8;
			}
			&:active {
				background: #116d6a;
			}
		}
		.ant-btn-default {
			border-radius: 40px;
			color: #199a97;
			min-width: 80px;
			border-color: #199a97;
			span {
				font-weight: 800;
			}

			&:hover {
				border-color: #1ebcb8;
				color: #1ebcb8;
			}
			&:active {
				border-color: #116d6a;
				color: #116d6a;
			}
		}
	}
}
// 工作台确认弹窗样式
.workbench-confirm {
	.anticon {
		display: none;
	}
	.confirm-title {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		span {
			margin-left: 8px;
			font-size: 20px;
			font-weight: 800;
		}
	}
	.ant-modal-confirm-btns {
		margin-top: 16px;
		.ant-btn-default {
			min-width: 76px;
			height: 40px;
			border-radius: 40px;
			color: #199a97;
			min-width: 80px;
			border-color: #199a97;
			span {
				font-weight: 600;
			}

			&:hover {
				border-color: #1ebcb8;
				color: #1ebcb8;
			}
			&:active {
				border-color: #116d6a;
				color: #116d6a;
			}
		}
		.ant-btn-primary {
			min-width: 76px;
			height: 40px;
			border-radius: 40px;
			background: #199a97;
			min-width: 80px;
			font-weight: 600;
			&:hover {
				background: #1ebcb8;
			}
			&:active {
				background: #116d6a;
			}
		}
	}
}
/* 红色 checkbox */
.red-check,
.red-check:hover {
	.ant-checkbox .ant-checkbox-inner {
		border-color: #fe2c55 !important;
	}
}

.ant-btn-primary {
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
	&:focus,
	&:hover,
	&:active {
		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
	}
}

/* 绿色link按钮 */
.ant-btn.line-primary {
	border: none;
	height: auto;
	box-shadow: none;
	color: #199a97;
	padding: 0;
	cursor: pointer;
	span {
		text-decoration: underline;
	}
}

.ant-btn-primary:disabled {
	border: 1px solid #d9d9d9;
}

// 重置底部抽屉自带阴影
.ant-drawer-bottom > .ant-drawer-content-wrapper {
	box-shadow: none;
	.ant-drawer-header {
		border-bottom: none;
	}
	.ant-drawer-body {
		padding: 0;
	}
}

.ant-tooltip {
	max-width: 9999px;
}

#cart .ant-input-number {
	.ant-input-number-input {
		text-align: center;
		padding: 0 32px;
		height: 32px;
	}
}

.ant-select-focused .ant-select-selector {
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12) !important;
}

.ant-drawer-header .ant-drawer-header-title {
	.ant-drawer-close {
		order: 2;
	}
}

body .ant-modal .ant-modal-content {
	padding: 16px 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.ant-tabs-tab-active {
	.ant-tabs-tab-btn {
		font-weight: 800;
	}
}
.ant-tabs-tab + .ant-tabs-tab {
	margin-left: 24px !important;
}
.ant-picker-outlined:focus-within {
	box-shadow: none !important;
}

@keyframes shakeX {
	from,
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: translate3d(-5px, 0, 0);
		transform: translate3d(-5px, 0, 0);
	}

	20%,
	40%,
	60%,
	80% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0);
	}
}

// 默认填充密码后到颜色
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
	transition: background-color 5000s ease-in-out 0s !important;
}
