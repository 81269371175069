.exchangePopover {
	color: var(--Neutral-text-primary, rgba(0, 0, 0, 0.88));
	font-size: 14px;
	line-height: 22px;
}

.exchangePopover span {
	font-weight: 900;
}

.exchangeInfo {
	position: absolute;
	right: 0;
	bottom: -22px;
	color: var(--Neutral-text-regular, rgba(0, 0, 0, 0.65));
	font-size: 12px;
	line-height: 20px;
}
