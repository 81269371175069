.layoutHeader {
	padding: 0;
	height: 60px;
}

.siteHeader {
	/* position: fixed;
	top: 0; */
	box-sizing: border-box;
	max-width: 1440px;
	min-width: 1200px;
	width: 100%;
	height: 60px;
	padding: 14px 48px;
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	align-items: center;
	background: var(--Neutral-Absolute-13_black, #000);
	color: #fff;
	text-align: center;
}

.siteHeaderTabs {
	flex-grow: 1;
	box-sizing: border-box;
	padding: 0 100px;
	display: flex;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	/* 150% */
}

.siteHeaderTabs div {
	margin-right: 48px;
	cursor: pointer;
}

.siteHeaderPanel {
	flex-shrink: 0;
}

.siteHeaderExchange {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 16px;
}

.siteHeaderLogin {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 16px;
}

.siteHeaderCart {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 16px;
}

.siteHeaderPopover .ant-popover-content .ant-popover-inner {
	padding: 0;
	border: 1px #fff solid;
	border-radius: 16px;
}