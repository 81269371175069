.siteHeaderAvator {
	width: 26px;
	height: 26px;
	border-radius: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.login-name {
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.siteHeaderAvatorVip {
	width: 16px;
	height: 16px;
	position: absolute;
	top: -5px;
	right: -5px;
}
