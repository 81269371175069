.comp-paypal-loading {
	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(359deg);
		}
	}
	.spinner {
		display: inline-block;
		height: 44px;
		width: 44px;
		box-sizing: border-box;
		border-radius: 22px;
		animation: rotation 0.7s infinite linear;
	}
}
