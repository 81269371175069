@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: Avenir;
	font-style: normal;
	font-weight: 400;
	src:
		local('Avenir'),
		url(https://static-us.theckb.com/static-asset/assets/avenir_roman_12.woff)
			format('woff');
}

body {
	margin: 0;
	font-family:
		Avenir,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 1240px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.ant-btn-lg {
	height: auto;
}

.ant-btn:not(:disabled):focus-visible {
	outline: none !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	outline: none !important;
}

.drawerTransX .ant-drawer-content-wrapper {
	transform: translateX(0px) !important;
}

.drawerTransY .ant-drawer-content-wrapper {
	transform: translateY(0px) !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
	font-weight: 700;
}
/* * {
	font-size: 14px;
} */
