.activeCartNum {
	display: inline-flex;
	width: 100%;
	padding: 2px 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background: var(--Brand-red-normal, #fe2c55);
	color: var(--Neutral-Absolute-grey-1, #fff);
	font-size: 12px;
	font-weight: 800;
	line-height: 12px; /* 100% */
}

.noGoodsCartNum {
	display: inline-flex;
	width: 100%;
	padding: 2px 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background: var(--Neutral-Absolute-1_white, #fff);
	color: var(--Neutral-Absolute-13_black, #000);
	font-size: 12px;
	font-weight: 800;
	line-height: 12px; /* 100% */
}
